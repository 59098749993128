import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import * as Icon from "./Icon";
import * as Widget from "./Widget";
import * as Theme from "../Theme";
import ProductPromoZoneDrawerButton from "../Components/ProductPromoZoneDrawerButton";
import * as Cart from "../Contexts/CartContext";
import { Badge } from "antd";

const navItems = [
  { children: "全部商品", url: "/products" },
  { children: "購物車", url: "/cart" },
  { children: "會員中心", url: "/profile" },
  // { children: "會員中心", url: "/profile" },
  // { children: "購物車", url: "/cart" },
];

class Navbar extends React.Component {
  state = {
    openMenu: false,
  };

  render() {
    let { passBreakpoint } = this.props;
    let { cart } = this.props;

    return (
      <Style.Wrapper passBreakpoint={passBreakpoint}>
        <Style.Content>
          <div className="constraint">
            {this._smallScreenNavBar()}
            {this._largeScreenNavBar()}
          </div>
        </Style.Content>
      </Style.Wrapper>
    );
  }

  _smallScreenNavBar() {
    let { cart } = this.props;
    const toggleMenuState = () =>
      this.setState({ openMenu: !this.state.openMenu });
    return (
      <Style.SmallScreenWrapper>
        <div style={{ display: "flex", height: Theme.navbarHeightMore }}>
          <Widget.ColCenter onClick={toggleMenuState}>
            <Icon.Menu size={30} color={"#ccc"} css={"margin-right: 10px;"} />
          </Widget.ColCenter>
          <Link
            to="/"
            className="site-title"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src="../../images/logo.png" height="40" width="auto" />
          </Link>
        </div>

        <div style={{ display: "flex" }}>
          <Link to="/cart" style={{ marginRight: 15 }}>
            <div className="item">
              <Badge count={cart.data ? cart.data.items.length : 0}>
                <Icon.ShoppingCart size={30} color={"#ccc"} />
              </Badge>
            </div>
          </Link>
          <Link to="/profile">
            <div className="item">
              <Icon.Person size={30} color={"#ccc"} />
            </div>
          </Link>
        </div>

        <Style.SmallScreenMenu
          open={this.state.openMenu}
          onClick={toggleMenuState}
        >
          <div className="container">
            {[
              { children: <Icon.Home color="white" />, url: "/" },
              ...navItems,
            ].map(({ children, url }, idx) => (
              <Link
                to={url}
                key={idx}
                className={
                  "nav-item " + (this._inPath(url) ? "nav-item-selected" : "")
                }
              >
                {children}
              </Link>
            ))}
          </div>
        </Style.SmallScreenMenu>
      </Style.SmallScreenWrapper>
    );
  }

  _largeScreenNavBar() {
    let { cart } = this.props;
    return (
      <Style.LargeScreenWrapper>
        <Link to="/" className="site-title">
          <img src="../../images/logo.png" height="40" width="auto" />
        </Link>
        <div style={{ flex: 1 }} />
        {navItems.map(({ children, url }, idx) => {
          return (
            <Link
              to={url}
              key={idx}
              className={
                "nav-item " + (this._inPath(url) ? "nav-item-selected" : "")
              }
            >
              {children}
              {children === "購物車" && (
                <Badge
                  count={cart.data ? cart.data.items.length : 0}
                  style={{ marginLeft: 5 }}
                />
              )}
            </Link>
          );
        })}
      </Style.LargeScreenWrapper>
    );
  }

  _inPath = navItemUrl => {
    // TODO: very buggy impl, change to a formal one
    // notice: we should also consider things like /products and /product?id=xx
    return (
      this.props.pathname.replace(/\//g, "") === navItemUrl.replace(/\//g, "")
    );
  };
}

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${Theme.navbarHeightMore}px;
    z-index: 10;
    background: #ffffff;
    transition: background 200ms ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 0px 1px 10px rgba(136, 136, 136, 0.3);
    @media screen and (max-width: ${Theme.breakpoints.sm}px) {
      height: ${Theme.navbarHeightMore}px;
    }
  `,

  Content: styled.div`
    flex: 1;
    align-items: center;
    display: flex;

    & > .constraint {
      padding: 0px 20px;
      flex: 1;
      max-width: 1024px;
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
  `,

  LargeScreenWrapper: styled.div`
    @media screen and (max-width: ${Theme.breakpoints.lg}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .nav-item {
      margin-right: 15px;
      color: #444444;
      font-size: 14px;
      font-weight: lighter;
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    & > .nav-item-selected {
      font-weight: normal;
    }

    & > .nav-item :last-child {
      margin-right: 0px;
    }
  `,

  SmallScreenWrapper: styled.div`
    @media screen and (min-width: ${Theme.breakpoints.lg}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,

  SmallScreenMenu: styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${props => (props.open ? "0" : "-100vw")};
    background-color: ${props =>
      props.open ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};
    transition: background-color 250ms linear,
      left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
    z-index: 5;

    & > .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding-top: 30px;

      & > .nav-item {
        color: #ccc;
        font-size: 20px;
        height: 50px;
        text-decoration: none;
      }

      & > .nav-item-selected {
        color: ${Theme.colors.main};
      }
    }
  `,
};

export default Cart.withConsumer(Navbar);
