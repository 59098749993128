import React from "react";
import { pricingTypes } from "../pricingTypes";
const ApiProxyFactory = require("../ApiProxy");
const constants = require("../constants");

class ErrNoSpec extends Error {}
class ErrNoSuchSpec extends Error {}
class ErrNoSuchProduct extends Error {}

const Context = React.createContext();
const Actions = {};
const apiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

function toQuery(params) {
  let query = "";
  if (typeof params === "object") {
    query = Object.keys(params)
      .filter(key => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? "?" : "&"}${key}=${params[key]}`;
        return e;
      }, "");
  }
  // to ?ordering=created&..
  return query;
}

class Provider extends React.Component {
  constructor(props) {
    super(props);

    Actions.setToken = token => {
      apiProxy.setToken(token);
      this.setState({ isAuth: true });
    };

    Actions.fetchMyCourseList = async ({ category, sort = "-created" }) => {
      let params = {
        search: category.name !== "root" ? category.name : "",
        limit: 20,
        offset: 0,
        ordering: sort,
      };
      const resp = await apiProxy.get({
        path: `/api/subscription/relation` + toQuery(params),
      });

      return resp;
    };

    Actions.fetchVideos = async courseId => {
      return await apiProxy.get({
        path: `/api/product/relation?product_id=${courseId}`,
      });
    };

    Actions.fetchProducts = async ({ category, sort = "-created" }) => {
      let params = {
        search: category.name !== "root" ? category.name : "",
        limit: 20,
        offset: 0,
        ordering: sort,
      };
      const resp = await apiProxy.get({
        path: `/api/product` + toQuery(params),
      });

      let products = resp.results;

      return products;
    };

    Actions.fetchProduct = async id => {
      return apiProxy.get({
        path: `/api/product/${id}`,
      });
    };

    Actions.fetchPromoItems = async type => {
      // type = banner || top_zone || bottom_zone
      let promoItems = await apiProxy.get({
        path: `/api/promo_item/`,
      });

      return promoItems.filter(p => p.type === type);
    };

    Actions.fetchMySubscriptions = async () => {
      return apiProxy.get({
        path: `/api/subscription/relation`,
      });
    };

    Actions.createAQuestion = async data => {
      let formData = new FormData();
      if (data.product) {
        formData.append("product", data.product);
      } else if (data.video) {
        formData.append("video", data.video);
      } else {
      }
      if (data.image) {
        formData.append("image", data.image);
      }
      if (data.phone) {
        formData.append("phone", data.phone);
      }
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("title", data.title);
      formData.append("body", data.body);

      return apiProxy.formPost({
        path: `/api/question`,
        formData,
      });
    };
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

class Consumer extends React.Component {
  render() {
    return (
      <Context.Consumer>{state => this.props.children(state)}</Context.Consumer>
    );
  }
}

function withConsumer(Comp) {
  class WrappedComp extends React.Component {
    render() {
      return (
        <Consumer>{state => <Comp catalog={state} {...this.props} />}</Consumer>
      );
    }
  }

  WrappedComp.displayName = `WithCatalog-${Comp.displayName}`;
  return WrappedComp;
}

export { Provider, Consumer, withConsumer, Actions };
