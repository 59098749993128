const navbarHeight = 30;
const navbarHeightMore = 60;
const bgColorDark = "#141618";
const bgColorLight = "#efefef";
const textColorDark = "#black";
const textColorLight = "#ccc";
const lineColorDark = "#black";
const lineColorLight = "#ccc";
const breakpoints = {
  sm: 500,
  lg: 800,
};
const ratio = 1.775; //thumbnail ratio

// greenjump
const colors = {
  main: "#9BBC5A", // green
  sub: "#F0E658", //  yellow
  text: "#444444", // black
  textReverse: "#ffffff",
};

const pageContentWidth = "1024px";
const titleFontSize = "20px";

export {
  navbarHeight,
  navbarHeightMore,
  bgColorDark,
  bgColorLight,
  textColorDark,
  textColorLight,
  lineColorDark,
  lineColorLight,
  breakpoints,
  colors,
  ratio,
  pageContentWidth,
  titleFontSize,
};
