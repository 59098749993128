import React from "react";
import { connect, Provider as ReduxProvider } from "react-redux";
import { getStore } from "./Store";
import Layout from "./Layout";
import Selectors from "./Selectors";
import ActionCreator from "./ActionCreator";
import * as Catalog from "./Contexts/CatalogContext";
import * as Cart from "./Contexts/CartContext";
import ErrorBoundary from "./ErrorBoundary";
import "antd/dist/antd.less";
import { Helmet } from "react-helmet";

class Page extends React.Component {
  async componentDidMount() {
    const { appActions, profile } = this.props;
    if (!profile) {
      await appActions.autoLogin();
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Layout key={this.props.lang} {...this.props}>
          {this.props.children}
        </Layout>
      </ErrorBoundary>
    );
  }
}

Page = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(Page);

class SimplePage extends React.Component {
  render() {
    return <ErrorBoundary>{this.props.children}</ErrorBoundary>;
  }
}

export function withPage(Comp) {
  class PageWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.store = getStore(props.pageContext.initialState);
    }

    render() {
      let { location } = this.props;
      if (location.pathname.indexOf("/course") > -1) {
        return (
          <SimplePage {...this.props}>
            <Helmet>
              <link rel="shortcut icon" href="/favicon.ico" />
              <title>賽斯教育</title>
              <style>{"body { background-color: black; }"}</style>
            </Helmet>
            <Comp {...this.props}>{this.props.children}</Comp>
          </SimplePage>
        );
      }

      return (
        <ReduxProvider store={this.store}>
          <Catalog.Provider latestSpec={this.props.pageContext.latestSpec}>
            <Cart.Provider>
              <Page {...this.props}>
                <Comp {...this.props}>{this.props.children}</Comp>
              </Page>
            </Cart.Provider>
          </Catalog.Provider>
        </ReduxProvider>
      );
    }
  }

  PageWrapper.displayName = "Page-" + Comp.displayName;
  return PageWrapper;
}
