const ApiProxyFactory = require("./ApiProxy");
const constants = require("./constants");

const ApiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

module.exports = {
  getToken: () => {
    return ApiProxy.token;
  },

  setToken: token => {
    ApiProxy.setToken(token);
  },

  echo: async msg => {
    return msg;
  },

  register_step1: async ({ email }) => {
    return ApiProxy.post({
      path: `/auth/validation/request`,
      data: {
        identity: email,
      },
    });
  },

  register_step2: async ({ validation_key, validation_code }) => {
    return ApiProxy.post({
      withHost: true,
      path: `https://auth-stg.revtel-api.com/v2/validation/confirm/${validation_key}/${validation_code}?client_id=greenjump`,
    });
  },

  register: async ({ password, access_token }) => {
    return ApiProxy.post({
      path: `/auth/user/register`,
      data: {
        password,
        access_token,
      },
    });
  },

  googleLogin: async token => {
    return ApiProxy.post({
      path: `/auth/google`,
      data: {
        token,
      },
    });
  },

  facebookLogin: async token => {
    return ApiProxy.post({
      path: `/auth/facebook`,
      data: {
        token,
      },
    });
  },

  login: async ({ email, identity, password }) => {
    return ApiProxy.post({
      path: `/auth/user/login`,
      data: {
        identity,
        email,
        password,
      },
    });
  },

  getAccessToken: async ({ refresh_token }) => {
    return ApiProxy.get({
      path: `https://auth-stg.revtel-api.com/v2/jwt/access?refresh_token=${refresh_token}`,
      withHost: true,
      secure: false,
    });
  },

  getMyProfile: async () => {
    return ApiProxy.get({
      path: `/auth/user/self`,
    });
  },

  async editMyProfile(data) {
    let formData = new FormData();
    delete data.user;
    for (let key in data) {
      formData.append(key, data[key]);
    }

    return ApiProxy.formPut({
      path: "/auth/user/self",
      formData,
    });
  },

  async forgetPassword({ email }) {
    return ApiProxy.post({
      path: `/api/user/password/forgot/`,
      data: {
        email,
      },
    });
  },

  async changePassword({ username, password, newPassword }) {
    return ApiProxy.post({
      path: `/auth/user/password`,
      data: {
        username,
        old_password: password,
        new_password: newPassword,
      },
    });
  },

  async searchProfile({ keyword }) {
    return ApiProxy.get({
      path: `/api/user/profile/all/?search=${keyword}`,
    });
  },

  async createCustomOrder({ note, amount, quantity, buyer, config }) {
    return ApiProxy.post({
      path: `/api/order/custom/`,
      data: {
        item_amount: amount,
        item_quantity: quantity,
        note,
        buyer,
        config,
      },
    });
  },

  async adminUpdateOrder({ id, buyer }) {
    return ApiProxy.put({
      path: `/api/order/${id}/`,
      data: {
        buyer_id: buyer,
      },
    });
  },

  async contactFormSendEmail(data) {
    if (!data.data) {
      delete data.data;
    }
    return ApiProxy.post({
      path: `/api/contact/`,
      data,
    });
  },

  async addFavorite(data) {
    return ApiProxy.post({
      path: `/api/favorite`,
      data,
    });
  },

  async deleteFavorite(id) {
    return ApiProxy.delete({
      path: `/api/favorite/${id}`,
    });
  },
};
