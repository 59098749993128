import Actions from "./Actions";

const initialState = {
  autologinProcessing: false,
};

export default function reducer(state = initialState, action) {
  let { orders } = state;
  switch (action.type) {
    case Actions.AUTO_LOGIN_PROCESSING:
      return {
        ...state,
        autologinProcessing: action.payload,
      };

    default:
      return state;
  }
}

export function autologinProcessing(state) {
  return state.autologinProcessing;
}
