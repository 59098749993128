import styled from "styled-components";
import * as Theme from "../Theme";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  ${props => props.css || ""}
`;

const RowCenter = styled(Row)`
  justify-content: center;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  ${props => props.css || ""}
`;

const ColCenter = styled(Col)`
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  height: ${props => props.px || 1}px;
  ${props => props.css || ""}
`;

const LineLight = styled.div`
  height: ${props => props.px || 1}px;
  background-color: ${Theme.lineColorLight};
  ${props => props.css || ""}
`;

const LineDark = styled.div`
  height: ${props => props.px || 1}px;
  background-color: ${Theme.lineColorDark};
  ${props => props.css || ""}
`;

const ToggleDrawer = styled.div`
  display: none;

  /* @media only screen and (max-width: 992px) {
    display: block;
  } */

  @media only screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 25px;
    bottom: 95px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    cursor: pointer;
    background-color: ${Theme.colors.main};
    box-shadow: 0px 2px 8px -3px #000;
  }
`;

const PillHeader = styled.div`
  margin-top: 20px;
  border: 1px solid #cbcbcb;
  padding: 15px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;

  &::before {
    content: " ";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    bottom: -10px;
    background-color: #cbcbcb;
  }
`;

export {
  Row,
  Col,
  RowCenter,
  ColCenter,
  Line,
  LineDark,
  LineLight,
  ToggleDrawer,
  PillHeader,
};
