const nodeFetch = require("node-fetch");

const universalFetch = (function getCorrectFetchImpl() {
  try {
    return fetch;
  } catch (ex) {
    return nodeFetch;
  }
})();

function addTailingSlashForPath(url) {
  let [path, query] = url.split("?");
  // if (path[path.length - 1] !== "/") {
  //   path += "/";
  // }
  return `${path}${query ? "?" + query : ""}`;
}

class _ApiProxy {
  constructor({ apiUrl }) {
    this._commonHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    this.apiUrl = apiUrl;
    this.apiToken = null;
  }

  _buildEndpoint({ path, withHost = false }) {
    if (withHost) {
      return path;
    }

    // `withHost` means we're sending req to our own API server,
    // which is DRF based and we hope to see tailing slash
    path = addTailingSlashForPath(path);
    return path ? `${this.apiUrl}${path}` : null;
  }

  _buildHeaders({ extraHeaders, skipCommonHeader = false, secure = true }) {
    let authHeader =
      this.apiToken && secure
        ? { Authorization: `Bearer ${this.apiToken}` }
        : {};
    if (skipCommonHeader) {
      return authHeader;
    }
    return {
      ...this._commonHeader,
      ...authHeader,
    };
  }

  setToken(token) {
    this.apiToken = token;
  }

  get({ path, extraHeaders = {}, withHost = false, secure = true }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: "GET",
      headers: this._buildHeaders({ extraHeaders, secure }),
    }).then(response => {
      if (response.status === 204) {
        return null;
      }

      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  post({ path, extraHeaders = {}, data, withHost = false, secure = true }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: "POST",
      headers: this._buildHeaders({ extraHeaders, secure }),
      body: JSON.stringify(data),
    }).then(response => {
      if (response.status === 204) {
        return null;
      }

      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  put({ path, extraHeaders = {}, data, withHost = false, secure = true }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: "PUT",
      headers: this._buildHeaders({ extraHeaders, secure }),
      body: JSON.stringify(data),
    }).then(response => {
      if (response.status === 204) {
        return null;
      }

      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  delete({ path, extraHeaders = {}, withHost = false, secure = true }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: "DELETE",
      headers: this._buildHeaders({ extraHeaders, secure }),
    }).then(response => {
      if (response.status === 204) {
        return null;
      }

      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  formPost({ path, extraHeaders = {}, formData, withHost = false }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: "POST",
      headers: this._buildHeaders({ extraHeaders, skipCommonHeader: true }),
      body: formData,
    }).then(response => {
      if (response.status === 204) {
        return null;
      }

      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  formPut({ path, extraHeaders = {}, formData, withHost = false }) {
    return universalFetch(this._buildEndpoint({ path, withHost }), {
      method: "PUT",
      headers: this._buildHeaders({ extraHeaders, skipCommonHeader: true }),
      body: formData,
    }).then(response => {
      if (response.status === 204) {
        return null;
      }

      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({ status: response.status });
    });
  }

  getImage(imageUrl) {
    return this._buildEndpoint({ path: imageUrl });
  }
}

module.exports = _ApiProxy;
