import React, { Component } from "react";
import "./index.css";
import styled from "styled-components";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Delighters from "./Components/ReactDelighters";
import { Helmet } from "react-helmet";
import * as Theme from "./Theme";
import LoginModal from "./Modals/LoginModal";
import { connect } from "react-redux";
import ActionCreator from "./ActionCreator";
import Selectors from "./Selectors";
import Constants from "./constants";
import * as Ant from "antd";

function checkPaddingAndNavbar(pathname) {
  return {
    withPaddingTop: true,
    hasNavbar: true,
  };
}

function normalizePath(path) {
  if (path.slice(0, 2) === "//") {
    path = path.slice(1);
  }

  if (path[path.length - 1] !== "/") {
    path += "/";
  }

  return path;
}

class Layout extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.autologin && this.props.autologin) {
      Ant.message.loading("正在自動登入");
    }
  }

  render() {
    const { location, profile } = this.props;
    const pathname = normalizePath(location.pathname);
    const { withPaddingTop, hasNavbar } = checkPaddingAndNavbar(pathname);
    let disableContent = false;

    if (!profile && pathname.indexOf("order") > -1) {
      disableContent = true;
    }

    return (
      <Delighters>
        <Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <link rel="shortcut icon" href="/favicon.png" />
            <title>易境永續設計顧問</title>
          </Helmet>

          {hasNavbar && (
            <Navbar
              passBreakpoint={this.passBreakpoint || withPaddingTop}
              pathname={pathname}
            />
          )}

          {disableContent && (
            <Content>
              <h3 style={{ textAlign: "center", padding: 20 }}>
                網頁跳轉中，請稍候
              </h3>
            </Content>
          )}

          {!disableContent && <Content>{this.props.children}</Content>}

          <Footer pathname={pathname} />

          <LoginModal />
        </Wrapper>
      </Delighters>
    );
  }

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

const Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  padding-top: ${props => (props.withPaddingTop ? Theme.navbarHeight : 0)}px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${Theme.breakpoints.lg}px) {
    padding-top: ${props =>
      props.withPaddingTop ? Theme.navbarHeightMore : 0}px;
  }
`;

const Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    autologin: Selectors.autologinProcessing(state),
  }),
  ActionCreator
)(Layout);
