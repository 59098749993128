import React from "react";
import styled from "styled-components";

class PromoZones extends React.Component {
  render() {
    const { promoZones, onClick } = this.props;

    return (
      <div>
        {promoZones.map(zone => (
          <PromoItem key={zone.id} onClick={() => onClick(zone)}>
            {zone.label || zone.name || "unknown"}
          </PromoItem>
        ))}
      </div>
    );
  }
}

const PromoItem = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 12px;
`;

export default PromoZones;
