const { CATEGORY_COLORS } = require("./Utils/ProductUtil");

const tree = {
  name: "root",
  display: "全部商品",
  children: [
    {
      // virtual category for filter display
      name: "",
      display: "全部商品",
      children: [],
    },
  ].concat(
    Object.keys(CATEGORY_COLORS).map(x => ({
      name: x,
      display: x,
      children: [],
    }))
  ),
  // [
  //   {
  //     // virtual category for filter display
  //     name: "",
  //     display: "全部商品",
  //     children: [],
  //   },
  //   {
  //     name: "最新",
  //     display: "最新",
  //     children: [],
  //   },
  //   {
  //     name: "免費",
  //     display: "免費",
  //     children: [],
  //   },
  //   {
  //     name: "募資",
  //     display: "募資",
  //     children: [],
  //   },
  //   {
  //     name: "推薦",
  //     display: "推薦",
  //     children: [],
  //   },
  //   // {
  //   //   name: "客製化多功能商品",
  //   //   display: "客製化多功能商品",
  //   //   children: [],
  //   //   // children: [
  //   //   //   {
  //   //   //     name: "吸水杯墊",
  //   //   //     display: "吸水杯墊",
  //   //   //     children: [],
  //   //   //   },
  //   //   //   {
  //   //   //     // TODO: temp fake cat.
  //   //   //     name: "不吸水杯墊",
  //   //   //     display: "不吸水杯墊",
  //   //   //     children: [],
  //   //   //   },
  //   //   // ],
  //   // },
  // ],
};

module.exports = tree;
exports.default = tree;
