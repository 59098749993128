const PRODUCT_TYPE = {
  free: "免費",
  payment: "付費",
  fundraising: "募資",
};

const CATEGORY_COLORS = {
  綠建築分類1: "#1F4E5A", // dark green
  綠建築分類2: "#029C81", // green
  綠建築分類3: "#FFDB69", //yellow
  綠建築分類4: "#FFA658", //light orange
  綠建築分類5: "#EA5F40", //orange
  綠建築分類6: "#516F71",
};
function getLabels(product) {
  let labels = product.label ? product.label.split(",") : [];
  return labels;
}

module.exports = {
  PRODUCT_TYPE,
  CATEGORY_COLORS,
  getLabels,
};
