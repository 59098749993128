import React from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as Ant from "antd";
import * as Catalog from "../Contexts/CatalogContext";
import styled from "styled-components";
import PromoZones from "../Components/PromoZones";
import categoryTreeRoot from "../productCategories";
import CategoryMenuItem from "../Components/CategoryMenuItem";
const queryString = require("query-string");

class ProductPromoZoneDrawerButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false,
      promoZones: [],
    };
  }

  componentDidMount() {
    //this._fetchPromoZones();
  }

  render() {
    let { buttonComp } = this.props;
    let { showDrawer, promoZones } = this.state;

    return (
      <Wrapper>
        <ToggleDrawer onClick={() => this.setState({ showDrawer: true })}>
          {buttonComp}
        </ToggleDrawer>

        <Ant.Drawer
          title="全部商品"
          placement="left"
          closable={false}
          onClose={() => this.setState({ showDrawer: false })}
          visible={showDrawer}
        >
          <CategoryMenuItem
            node={categoryTreeRoot}
            goToCategory={this._goToCat}
          />

          <PromoZones promoZones={promoZones} onClick={this._goToPromoZone} />
        </Ant.Drawer>
      </Wrapper>
    );
  }

  _goToCat = cat => {
    const { navActions } = this.props;
    this.setState({ showDrawer: false });
    navActions.push(`/products?name=${cat.name}`);
  };

  _goToPromoZone = zone => {
    const { navActions } = this.props;
    this.setState({ showDrawer: false });
    navActions.push(`/products?zone=${zone.id}`);
  };

  _fetchPromoZones = async () => {
    try {
      this.setState({
        promoZones: await Catalog.Actions.fetchPromoItems("bottom_zone"),
      });
    } catch (ex) {
      console.warn(ex);
    }
  };
}

const Wrapper = styled.div``;

const Content = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
`;

const ToggleDrawer = styled.div``;

export default connect(null, ActionCreator)(ProductPromoZoneDrawerButton);
