import { navigate } from "gatsby";
import Actions from "./Reducers/Actions";
import Api from "./Api";
import * as Cart from "./Contexts/CartContext";
import * as Catalog from "./Contexts/CatalogContext";

const validator = require("validator");
let register_validation_key = "";

export default dispatch => ({
  navActions: {
    push: async (url, param) => {
      if (!param) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}`, param);
      }
    },
  },

  appActions: {
    setLang: lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    },

    setToken: token => {
      Api.setToken(token);
    },

    echo: async () => {
      return await Api.echo("hello");
    },

    googleLogin: async resp => {
      let token = resp.tokenId;
      try {
        // get the token/refresh token
        let resp_from_mono = await Api.googleLogin(token);
        console.log("googleLogin success!", resp_from_mono);
        window.localStorage.setItem(
          "refresh_token",
          resp_from_mono.refresh_token
        );
      } catch (err) {
        console.warn(err);
      }
    },

    facebookLogin: async resp => {
      let token = resp.accessToken;
      try {
        // get the token/refresh token
        let resp_from_mono = await Api.facebookLogin(token);
        console.log("facebookLogin success!", resp_from_mono);
        window.localStorage.setItem(
          "refresh_token",
          resp_from_mono.refresh_token
        );
      } catch (err) {
        console.warn(err);
      }
    },

    autoLogin: async () => {
      let refresh_token = window.localStorage.getItem("refresh_token");
      if (refresh_token) {
        dispatch({ type: Actions.AUTO_LOGIN_PROCESSING, payload: true });
        let accessToken = await Api.getAccessToken({ refresh_token });
        let { token } = accessToken;
        Api.setToken(token);
        Cart.Actions.setToken(token);
        Catalog.Actions.setToken(token);
        let response = null;
        try {
          response = await Api.getMyProfile();
          window.localStorage.setItem("token", token);
        } catch (ex) {
          if (!(ex instanceof TypeError)) {
            Api.setToken(null);
            Cart.Actions.setToken(null);
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("refresh_token");
          }
          dispatch({ type: Actions.AUTO_LOGIN_PROCESSING, payload: false });
          return false;
        }
        dispatch({ type: Actions.LOGIN, payload: response });
        dispatch({ type: Actions.AUTO_LOGIN_PROCESSING, payload: false });
        return true;
      }
      return false;
    },

    login: async ({ identity, password }) => {
      try {
        let response = null;
        response = await Api.login({ identity, password });

        Api.setToken(response.token);
        Cart.Actions.setToken(response.token);
        Catalog.Actions.setToken(response.token);
        window.localStorage.setItem("token", response.token);
        window.localStorage.setItem("refresh_token", response.refresh_token);
        dispatch({ type: Actions.LOGIN, payload: response });
      } catch (ex) {
        Api.setToken(null);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("refresh_token");
        throw ex;
      }
    },

    logout: async () => {
      dispatch({ type: Actions.LOGOUT, payload: null });
      Api.setToken(null);
      Cart.Actions.setToken(null);
      Catalog.Actions.setToken(null);
      window.localStorage.removeItem("id");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("refresh_token");
    },

    register: async ({ email, password }) => {
      let registerResp = await Api.register_step1({ email });
      register_validation_key = registerResp.validation;
    },

    register_validation: async ({ validation_code, password }) => {
      let resp = await Api.register_step2({
        validation_key: register_validation_key,
        validation_code,
      });
      console.log("real pw---->", password);
      let resp2 = await Api.register({
        password,
        access_token: resp.access_token,
      });
      window.localStorage.setItem("refresh_token", resp2.refresh_token);
    },

    editMyProfile: async data => {
      data.avatar = "";
      let payload = await Api.editMyProfile(data);
      dispatch({
        type: Actions.AUTO_LOGIN,
        payload,
      });
      return payload;
    },

    getMyProfile: async () => {
      try {
        await Api.getMyProfile();
      } catch (ex) {
        console.warn(ex);
      }
    },

    forgetPassword: async ({ email }) => {
      return Api.forgetPassword({ email });
    },

    changePassword: async ({ username, password, newPassword }) => {
      try {
        await Api.changePassword({ username, password, newPassword });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    searchProfile: async ({ keyword }) => {
      return Api.searchProfile({ keyword });
    },

    createCustomOrder: async ({ note, amount, quantity, buyer, config }) => {
      return Api.createCustomOrder({ note, amount, quantity, buyer, config });
    },

    adminUpdateOrderBuyer: async ({ id, buyer }) => {
      return Api.adminUpdateOrder({ id, buyer });
    },

    contactFromSendEmail: async ({
      subject,
      message,
      name,
      phone,
      local_phone,
      email,
      data = "",
    }) => {
      return Api.contactFormSendEmail({
        subject,
        message,
        name,
        phone,
        local_phone,
        email,
        data,
      });
    },

    addFavorite: async params => {
      try {
        let resp = await Api.addFavorite(params);
        return resp;
      } catch (err) {
        throw err;
      }
    },

    deleteFavorite: async id => {
      try {
        let resp = await Api.deleteFavorite(id);
      } catch (err) {
        throw err;
      }
    },
  },
});
