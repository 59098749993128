import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as Theme from "../Theme";

class Footer extends Component {
  render() {
    let { extraStyle = "", pathname } = this.props;

    if (
      pathname === "/cart/" ||
      pathname === "/checkout/" ||
      pathname === "/order/"
    ) {
      return null;
    }

    return (
      <Wrapper extraStyle={extraStyle}>
        <Info>
          {/* <Link to="/">FAQ</Link> */}
          <img
            src="../../images/logo.png"
            width="200"
            height="auto"
            style={{ objectFit: "contain" }}
          />
          {/* <Block flex="3">
            <p>
              <span style={{ color: Theme.colors.main }}>S</span>piritual 心靈
            </p>
            <p>
              <span style={{ color: Theme.colors.main }}>E</span>nlightenment
              啟發
            </p>
            <p>
              <span style={{ color: Theme.colors.main }}>T</span>ransformation
              轉化
            </p>
            <p>
              <span style={{ color: Theme.colors.main }}>H</span>omecoming 回家
            </p>
          </Block>
          <Block>
            <p className="title">產品資訊</p>
            <Link to="/products" className="nav-item">
              全部商品
            </Link>
          
            <Link to="/profile" className="nav-item">
              會員中心
            </Link>
          </Block>
          <Block flex="2">
            <p className="title">聯絡我們</p>
            <p>電子郵件：ho.mis@seth.org.tw</p>
            <p>地址：台中市北區崇德路一段631號10樓之1</p>
          </Block>
         */}
        </Info>
        <div className="copyright">
          版權所有 2020 易境永續設計顧問 All Rights Reserved.
        </div>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: #fff;
  width: 100vw;
  padding: 20px 0px 0px 0px;
  color: ${Theme.textColorLight};
  display: flex;
  flex-direction: column;

  & > .copyright {
    padding: 15px;
    color: #ffffff;
    background-color: ${Theme.colors.main};
    text-align: center;
    font-size: 12px;
    align-self: stretch;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  ${props => props.extraStyle};
`;

let Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px;
`;

let Block = styled.div`
  margin: 0px 20px;
  /* max-width: 350px; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  flex-grow: ${props => props.flex || 1};

  ${props => props.css}

  & > .nav-item {
    margin-bottom: 7px;
    font-size: 12px;
    color: #6b6b6b;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  & > .title {
    font-size: 12px;
    color: #6b6b6b;
    font-weight: 500;
    line-height: 15px;
  }

  & > label {
    font-size: 12px;
    color: ${Theme.colors.main};
    line-height: 15px;
  }
  & > p {
    font-size: 12px;
    color: #6b6b6b;
    line-height: 15px;
  }
  & > .company-name {
    color: #37c491;
    font-size: 30px;
  }

  & > .contact {
    font-size: 22px;
  }

  & > .menu {
    flex-grow: 1;
  }

  & > .row-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 20px;
    width: 100%;
    min-width: 200px;
  }
`;

export default connect(null, ActionCreator)(Footer);
