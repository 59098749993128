const Settings = {
    env: "stg",
    webUrl: "http://localhost:3000",
    apiUrl: "https://greenjump-api.revtel2.com",
    mediaHost: "https://greenjump-api.revtel2.com",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/stg.hecafe.com.tw",
    debug: true,
    googleClientId: "896372984487-2nglbfcntarp7bcs7r1363ifg79r392c.apps.googleusercontent.com",
    facebookClientId: "698111304245540", 
    userType: "aws",
    slsApi: {
      host: "http://localhost:3000",
    },
    revClientId: "",
    revAuthServiceApi: "https://auth-stg.revtel-api.com",
    revBlogDashboardUrl: "https://revtel-blog.netlify.app",
    revBlogServiceApi: "https://blog.revtel-api.com",
};

module.exports = Settings;

