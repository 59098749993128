import React from "react";
import * as Icon from "../Components/Icon";

class CategoryMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.isRoot = props.node.name === "root";
    this.hasChildren = props.node.children.length > 0;
  }

  render() {
    const { node, goToCategory } = this.props;
    const { open } = this.state;

    return (
      <div>
        {!this.isRoot && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 32,
              cursor: "pointer",
            }}
            onClick={this._toggleMenu}
          >
            <div style={{ flex: 1, fontSize: 12 }} onClick={this._onLinkClick}>
              {node.display}
            </div>
            {this.hasChildren && open && (
              <Icon.ExpandLess size={18} color="#717071" />
            )}
            {this.hasChildren && !open && (
              <Icon.ExpandMore size={18} color="#717071" />
            )}
          </div>
        )}

        {(open || this.isRoot) && (
          <div style={{ paddingLeft: this.isRoot ? 0 : 15 }}>
            {node.children.map(child => {
              return (
                <CategoryMenuItem
                  node={child}
                  key={child.name}
                  goToCategory={goToCategory}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }

  _toggleMenu = () => {
    if (!this.hasChildren) {
      return;
    }

    const { open } = this.state;
    this.setState({ open: !open });
  };

  _onLinkClick = e => {
    const { goToCategory, node } = this.props;
    goToCategory && goToCategory(node);
    e.stopPropagation();
  };
}

export default CategoryMenuItem;
