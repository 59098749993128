const pricingTypes = [
  "default",
  "single",
  "square",
  "starting",
  "digital",
  "size",
  "custom",
];

module.exports = {
  pricingTypes,
};
