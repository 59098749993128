import React from "react";
import * as Catalog from "./CatalogContext";
const ApiProxyFactory = require("../ApiProxy");
const constants = require("../constants");

function transQuery(params) {
  let query = "";
  if (typeof params === "object") {
    query = Object.keys(params)
      .filter(key => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? "?" : "&"}${key}=${params[key]}`;
        return e;
      }, "");
  }
  return query;
}

class ErrCartNotAvailable extends Error {}

const Context = React.createContext();
const Actions = {};

const apiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

class Provider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isAuth: false,
      loading: false,
    };

    Actions.setToken = token => {
      apiProxy.setToken(token);
      this.setState({ isAuth: true });
    };

    Actions.addItem = async id => {
      if (!this.state.data) {
        try {
          await Actions.fetchCart();
        } catch (error) {
          throw new ErrCartNotAvailable();
        }
      }

      try {
        let resp = await apiProxy.post({
          path: "/api/cart/item/add",
          data: {
            product: id,
            config: { quantity: 1 },
          },
        });
        this.setState({ data: resp });
      } catch (ex) {
        console.warn("DBG", ex);
        throw ex;
      }
    };

    Actions.removeItem = async index => {
      let resp = await apiProxy.post({
        path: "/api/cart/item/delete",
        data: { index },
      });
      this.setState({ data: resp });
    };

    Actions.updateItem = async (index, item) => {
      let resp = await apiProxy.post({
        path: "/api/cart2/edit_item/",
        data: { index, config: item.config },
      });
      this.setState({ data: resp });
    };

    Actions.editConfig = async config => {
      try {
        let resp = await apiProxy.post({
          path: "/api/cart/config/edit",
          data: config,
        });
        this.setState({ data: resp });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.checkout = async data => {
      //not request, it's redirect
      if (window) {
        data = {
          ...data,
        };
        let query = transQuery(data);

        window.location.assign(
          `${constants.apiUrl}/payment/checkout?token=${apiProxy.apiToken}&payment_type=neweb&payment_subtype=${data.payment_subtype}`
        );
      }
    };

    Actions.fetchCart = async () => {
      this.setState({ loading: true });
      try {
        let resp = await apiProxy.get({
          path: "/api/cart",
        });
        this.setState({ data: resp });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
      this.setState({ loading: false });
    };

    Actions.clearCart = async () => {
      try {
        let resp = await apiProxy.post({
          path: "/api/cart/clear/",
        });
        this.setState({ data: resp });
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.fetchOrder = async id => {
      try {
        let resp = await apiProxy.get({
          path: `/payment/order/${id}`,
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
      }
    };

    Actions.fetchOrders = async ({ payment_status, payment_type }) => {
      try {
        let resp = await apiProxy.get({
          path: `/payment/order?ordering=-created${(!!payment_status &&
            `&payment_status=${payment_status}`) ||
            ""}${(!!payment_type && `&payment_type=${payment_type}`) || ""}`, //?ordering=-create
        });
        return resp;
      } catch (ex) {
        console.warn("DBG", ex);
        return [];
      }
    };
  }

  componentDidMount() {
    Actions.fetchCart();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!prevState.isAuth && this.state.isAuth) {
      Actions.fetchCart();
    }
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

class Consumer extends React.Component {
  render() {
    return (
      <Context.Consumer>{state => this.props.children(state)}</Context.Consumer>
    );
  }
}

function withConsumer(Comp) {
  class WrappedComp extends React.Component {
    render() {
      return (
        <Consumer>{state => <Comp cart={state} {...this.props} />}</Consumer>
      );
    }
  }

  WrappedComp.displayName = `WithCart-${Comp.displayName}`;
  return WrappedComp;
}

export { Provider, Consumer, withConsumer, Actions, ErrCartNotAvailable };
