import { combineReducers } from "redux";
import user from "./User";
import lang from "./Lang";
import appState from "./AppState";

export default combineReducers({
  user,
  lang,
  appState,
});
